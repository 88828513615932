<template>
    <div class="container-fluid">
        <errors-bag-list/>

        <b-card>
            <div class="row">
                <div class="col-12 text-right">
                    <b-button variant="success" @click="exportToCSV">Экспорт в CSV</b-button>
                    <b-button variant="primary" @click="generateReport">Сформировать</b-button>
                </div>
            </div>
            <b-row class="mt-2">
                <b-col cols="12">
                    <b-form-radio-group
                        v-model="filters.type"
                        :options="types"
                        buttons
                        button-variant="outline-dark"
                    />
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col md="3" class="mt-1">
                    <area-select v-model="filters.area_id"/>
                </b-col>
                <b-col md="6" class="mt-1">
                    <template v-if="filters.type === BY_DAY_TYPE">
                        <actual-shift-picker v-model="filters.actual_shift"/>
                    </template>
                    <template v-else-if="filters.type === BY_PERIOD_TYPE">
                        <b-row>
                            <b-col md="6">
                                <b-form-datepicker
                                    v-model="filters.period_date_from"
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder="Дата от"
                                />
                            </b-col>
                            <b-col md="6" class="mt-1">
                                <b-form-datepicker
                                    v-model="filters.period_date_to"
                                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                    :start-weekday="1"
                                    locale="ru"
                                    placeholder="Дата до"
                                />
                            </b-col>
                        </b-row>
                    </template>
                </b-col>
            </b-row>
        </b-card>

        <b-table :items="report" :fields="fields" hover responsive head-variant="dark"/>
    </div>
</template>

<script>
import moment from 'moment';

import CSV       from '@utils/csv';
import ErrorsBag from '@utils/errorsBag';
import {REPORTS} from '@utils/endpoints';

import ErrorsBagList     from '@components/_common/ErrorsBagList';
import ActualShiftPicker from '@components/_common/ActualShiftPicker';
import {AreaSelect}        from '@components';

const BY_DAY_TYPE = 'by_day';
const BY_PERIOD_TYPE = 'by_period';

const DATE_FORMAT = 'YYYY-MM-DD';

export default {
    name: 'TemperatureAfterShock',
    components: {
        ActualShiftPicker,
        ErrorsBagList,
        AreaSelect,
    },
    watch: {
        'filters.type'() {
            this.report = [];

            this.filters.actual_shift = null;
            this.filters.period_date_to = null;
            this.filters.period_date_from = null;
        }
    },
    data() {
        return {
            BY_DAY_TYPE,
            BY_PERIOD_TYPE,

            filters: {
                type: BY_DAY_TYPE,
                actual_shift: null,
                period_date_to: null,
                period_date_from: null,
                area_id: null,
            },

            types: [
                {text: 'За сутки', value: BY_DAY_TYPE},
                {text: 'За период', value: BY_PERIOD_TYPE},
            ],

            report: [],
            fields: [
                {
                    key: 'date',
                    label: 'Дата',
                    formatter: function (value) {
                        return moment(value).format('DD.MM.YYYY');
                    }
                },
                {
                    key: 'time',
                    label: 'Время',
                    formatter: function (value, key, item) {
                        return moment(item.date).format('HH:MM');
                    }
                },
                {key: 'shift.name', label: 'Смена'},
                {key: 'area.name', label: 'Площадка'},
                {key: 'nomenclature.name', label: 'Наименование ПФ'},
                {key: 'temperature_after_shock_1', label: 't, ℃ в толще ПФ после охлаждения, партия 1'},
                {key: 'temperature_after_shock_2', label: 't, ℃ в толще ПФ после охлаждения, партия 2'},
                {key: 'temperature_after_shock_3', label: 't, ℃ в толще ПФ после охлаждения, партия 3'},
                {key: 'temperature_after_shock_4', label: 't, ℃ в толще ПФ после охлаждения, партия 4'},
                {key: 'temperature_after_shock_5', label: 't, ℃ в толще ПФ после охлаждения, партия 5'},
                {key: 'temperature_after_shock_6', label: 't, ℃ в толще ПФ после охлаждения, партия 6'},
                {key: 'count', label: 'Количество, кг/шт'},
            ]
        };
    },
    methods: {
        prepareParams() {
            let params = {type: this.filters.type};

            switch (params.type) {
                case BY_DAY_TYPE:
                    if (this.filters.actual_shift) {
                        params.actual_shift_id = this.filters.actual_shift.id;
                    }
                    break;
                case BY_PERIOD_TYPE:
                    if (this.filters.period_date_to) {
                        params.date_to = this.filters.period_date_to;
                    }

                    if (this.filters.period_date_from) {
                        params.date_from = this.filters.period_date_from;
                    }
                    break;
            }

            if (this.filters.area_id) {
                params.area_id = this.filters.area_id;
            }

            return params;
        },
        generateReport() {
            ErrorsBag.discard();

            const params = this.prepareParams();

            this.$http
                .get(`${REPORTS}/temperature-after-shock`, {params})
                .then(
                    response => this.report = response.data,
                    response => ErrorsBag.fill(response.body)
                );
        },
        exportToCSV() {
            ErrorsBag.discard();

            const params = this.prepareParams();

            this.$http
                .post(`${REPORTS}/temperature-after-shock/export-to-csv`, params)
                .then(
                    response => {
                        let name = '';

                        switch (this.filters.type) {
                            case BY_DAY_TYPE:
                                name = this.filters.actual_shift.name;
                                break;
                            case BY_PERIOD_TYPE:
                                name = `${this.filters.period_date_from}-${this.filters.period_date_to}`;
                                break;
                        }

                        let filename = 'Отчет по температуре после шоковой ' + name;

                        CSV.download(response.data, filename);
                    },
                    response => ErrorsBag.fill(response.body)
                );
        }
    }
}
</script>
